.bregel {
	display: block;
	width: 100%;
	height: 40px;
/*	background-color: rgba(255,0,244,0.2); */
	vertical-align: top;
	margin-bottom: 10px;
}
.bregel .icon {
	position: relative;
	display: inline-block;
	width:10%;
	height: 100%;
	vertical-align: top;
}
.bregel .icon svg {
	width:90%;
}
.bregel .txt {
	position: relative;
	display: inline-block;
	top:0px;
	width:75%;
	height: 100%;
/*	background-color: rgba(255,244,0,0.2); */
	vertical-align: top;
	padding-left:10px;
	font-size: 20px;
	line-height: 40px;
}
.bregel .count {
	display: inline-block;
	width:15%;
	height: 100%;
/*	background-color: rgba(244,0,0,0.2); */
	vertical-align: top;

	padding-left:10px;
	font-size: 20px;
	line-height: 40px;
}

.avatar {
	width:100%;
}